<template>
  <!-- Canvas element to render the 3D scene -->
  <canvas ref="container" class="container-fluid"></canvas>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import HolographicMaterial from './HolographicMaterialVanilla.js';

export default {
  name: 'coolLogo',
  data() {
    //I spent a while trying to get this rotation smooth and skip the unreadble mirror
    return {
      yRotation: 0, // Current rotation on the Y-axis in degrees
      targetRotation: 0, // Target rotation to smoothly rotate to (in degrees)
      rotationSpeed: 0.2, // Speed of rotation (in degrees)
      skipSpeed: 1.5, // Speed for the quick jump
      //vue debugging
      model: null, // The loaded model
      isJumping: false, // Flag to check if we are performing the quick jump
    };
  },
  mounted() {
    // Set up the Three.js scene
    const container = this.$refs.container;
    const scene = new THREE.Scene();

    const screenRes = {
      width: container.clientWidth,
      height: container.clientHeight,
    };

    const camera = new THREE.PerspectiveCamera(
      35,
      screenRes.width / screenRes.height,
      0.1,
      10000
    );
    camera.position.set(0, 0, 4);
    scene.add(camera);

    const controls = new OrbitControls(camera, container);
    controls.enableDamping = true;
    controls.maxDistance = 6;
    controls.minDistance = 2;
    controls.maxPolarAngle = Math.PI / 1.7;
    controls.minPolarAngle = 1.1;

    const light = new THREE.DirectionalLight();
    light.intensity = 1;
    light.position.set(-20, 20, 50);
    scene.add(light);

    const ambientLight = new THREE.AmbientLight();
    ambientLight.intensity = 2.9;
    scene.add(ambientLight);

    const renderer = new THREE.WebGLRenderer({
      canvas: container,
      powerPreference: 'high-performance',
      antialias: false,
      stencil: false,
      depth: false,
      alpha: true,
    });
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 0.7;
    renderer.setSize(screenRes.width, screenRes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));

    const holographicMaterial = new HolographicMaterial();

    // Load the 3D model
    const loader = new OBJLoader();
    loader.load('emas.obj', (obj) => {
      obj.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = holographicMaterial;
        }
      });
      this.model = obj; // Store the model in Vue data
      scene.add(obj);
    });

    // Handle window resizing
    window.addEventListener('resize', () => {
      screenRes.width = container.clientWidth;
      screenRes.height = container.clientHeight;

      camera.aspect = screenRes.width / screenRes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(screenRes.width, screenRes.height);
    });

    const animate = () => {
      if (this.model) {
        // Update the target rotation
        if (this.targetRotation < 90) {
          this.targetRotation += this.rotationSpeed; // Spin normally until 90
        }

        if (this.targetRotation >= 90 && this.targetRotation < 270) {
          this.targetRotation += this.skipSpeed; // Gradually jump to 270
          if (this.targetRotation >= 270) {
            this.targetRotation = 270; // Clamp to 270 once the jump is complete
          }
        }

        if (this.targetRotation >= 270) {
          this.targetRotation += this.rotationSpeed; // Resume spinning after 270
        }

        // Smooth interpolation of the rotation
        this.yRotation = THREE.MathUtils.lerp(
          this.yRotation,
          this.targetRotation,
          0.1
        );

        // Apply the rotation to the model
        this.model.rotation.y = THREE.MathUtils.degToRad(this.yRotation);

        // If we reached 360°, reset to 0° for an endless loop
        if (this.targetRotation >= 360) {
          this.targetRotation = 0;
        }
      }

      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();
  },
};
</script>
